import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import { Link } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

if (typeof window !== `undefined`) {
  var search = window.location.search.match(/[?&#]afcode=([^?&#]*)[?&#]?/);
}
if (typeof document !== `undefined`) {
  var cc = document.cookie.match(/[;]?serumAfcode=([^;]*)[;]?/);
}
search = search ? search[1] : null;
cc = cc ? cc[1] : null;

// クエリがなければクッキーを参照
var afcode = search || cc;
if (afcode) {
  var now = new Date();
  now.setHours(now.getHours() + 24);
  document.cookie = "serumAfcode=" + afcode + ";expires=" + now.toUTCString() + ";";
  afcode = "&afcode=" + afcode;
}
afcode = afcode || ''
const cartUrl = process.env.CART_SITE_URL;
const quizUrl = cartUrl + "/hotaru/questions?cleansing=1&argument=rVHtCJXQ&dmai=a62391f8a2f9f3" + afcode;

const assetVersion = "?v=20220318";

const colorSettings = {
  arrows: false,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  autoplaySpeed: 5000,
  speed: 2500,
  autoplay: true,
};

const AboutCleansingPage = () => (
    <Layout sectionClasses={"t-cleansing-about"} dataNamespace={"about_serum"} quizUrl={quizUrl} isHeadLogo={true}>
      <SEO title="About Cleansing" image={"https://hotaru-personalized.com/images/about/cleansing/ogp.jpg"}/>
      <div className="t-cleansing">
        <section className="t-cleansing__mv">
          <picture>
            <source media="(max-width:750px)" srcSet={"/images/about/cleansing/mv_sp.jpg" + assetVersion} />
            <img src={"/images/about/cleansing/mv_pc.jpg" + assetVersion} alt="" />
          </picture>
          <div className="t-cleansing__ttl t-cleansing__mv__ttl">
            <p className="t-cleansing__ttl__sub">HOTARU PERSONALIZED</p>
            <h1 className="t-cleansing__ttl__main">CLEANSING</h1>
          </div>
        </section>

        <div className="t-cleansing__inner">
          <section className="t-cleansing__concept">
            <div className="t-cleansing__concept__img">
              <img src={"/images/about/cleansing/concept_1.jpg" + assetVersion} alt="" />
              <div className="t-cleansing__concept__img__top__layer">
                <img src={"/images/about/cleansing/concept_2.jpg" + assetVersion} alt="" />
              </div>
            </div>
            <div className="t-cleansing__concept__txt">
              <div className="t-cleansing__ttl t-cleansing__concept__txt__ttl">
                <h2 className="t-cleansing__ttl__main">CONCEPT</h2>
                <span className="t-cleansing__ttl__sub">CLEANSING</span>
              </div>
              <p className="t-cleansing__desc">もう悩まなくていい。<br/>
                あなた専用<span className="t-cleansing__desc__notice">※1</span>のバランスで処方される<br />
                二層式クレンジング。<br />
                肌の土台を整え、スキンケアの効果をサポート。</p>
              <p className="t-cleansing__desc">3万通りの診断結果から<br/>
                クレンジング以上の体験を。</p>
              <p className="t-cleansing__notice">※1 AI診断で組み合わせたオーダーメイド。<br className="pc-hide"/>特定の用法用量を示すものではありません。</p>
            </div>
          </section>

          <div className="t-cleansing__point">
            <p className="t-cleansing__point__cat"><span className="t-cleansing__point__ttl">Point</span></p>
            <p className="t-cleansing__desc">肌の状態・肌悩みから導き出すオーダーメイドクレンジング。<br/>
              肌悩みや洗い上がりの好みまで、自分専用にカスタマイズ。</p>
            <div className="t-cleansing__point__block">
              <img className="" src={"/images/about/cleansing/point_01.jpg"} alt="" />
              <div className="t-cleansing__point__block__inner">
                <p className="t-cleansing__point__block__inner__num">01</p>
                <p className="txt-desc">革命的クレンジング</p>
                <hr />
                <p className="txt-desc">美容液層とクレンジング層からなる二層式クレンジング。<br />
                  肌の土台を整え、スキンケアの効果をサポート。毛穴の目立たたないキメのある肌へ導きます。
                </p>
              </div>
            </div>
            <div className="t-cleansing__point__block">
              <img className="" src={"/images/about/cleansing/point_02.jpg"} alt="" />
              <div className="t-cleansing__point__block__inner">
                <p className="t-cleansing__point__block__inner__num">02</p>
                <p className="txt-desc">肌のことを考えた7つの無添加</p>
                <hr />
                <p className="txt-desc">あなたの肌本来の力を引き出すために不要なものは無添加に。<br/>
                  肌本来のうるおい力をサポートすることを目指しました。
                </p>
              </div>
            </div>

            <div className="t-cleansing__keep">
              <div className="t-cleansing__keep__point">
                <div className="circle">
                  <img className="circle__img" src={"/images/about/cleansing/strike.png"} alt="" />
                  <div className="circle__txt">パラベン</div>
                </div>
                <div className="circle">
                  <img className="circle__img" src={"/images/about/cleansing/strike.png"} alt="" />
                  <div className="circle__txt">エタノール</div>
                </div>
                <div className="circle">
                  <img className="circle__img" src={"/images/about/cleansing/strike.png"} alt="" />
                  <div className="circle__txt">紫外線散乱剤</div>
                </div>
                <div className="circle">
                  <img className="circle__img" src={"/images/about/cleansing/strike.png"} alt="" />
                  <div className="circle__txt">合成香料</div>
                </div>
                <div className="circle">
                  <img className="circle__img" src={"/images/about/cleansing/strike.png"} alt="" />
                  <div className="circle__txt">安息香酸Na</div>
                </div>
                <div className="circle">
                  <img className="circle__img" src={"/images/about/cleansing/strike.png"} alt="" />
                  <div className="circle__txt">合成ポリマー</div>
                </div>
                <div className="circle">
                  <img className="circle__img" src={"/images/about/cleansing/strike.png"} alt="" />
                  <div className="circle__txt">ワックス</div>
                </div>
              </div>
            </div>

            <div>
              <Link to={quizUrl} className="button">ONLINE肌診断(無料)</Link>
            </div>
          </div>

          <section className="t-cleansing__color">
            <div className="t-cleansing__ttl">
              <h2 className="t-cleansing__ttl__main">THEME</h2>
            </div>
            <div className="t-cleansing__color__desc">
              <Slider {...colorSettings}>
                <div className="t-cleansing__color__item">
                  <div className="t-cleansing__color__item__inner">
                    <div className="t-cleansing__color__img">
                      <img src={"/images/about/cleansing/theme_tomorrow_is_another_day.jpg" + assetVersion} alt="" />
                    </div>
                    <div className="t-cleansing__color__txt">
                      <div className="t-cleansing__color__txt__inner">
                        <p className="t-cleansing__color__txt__name">TOMORROW IS ANOTHER DAY.</p>
                        <p className="t-cleansing__color__txt__ttl__main">明日は新たな一日</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="t-cleansing__color__item">
                  <div className="t-cleansing__color__item__inner">
                    <div className="t-cleansing__color__img">
                      <img src={"/images/about/cleansing/theme_don_t_worry_be_happy.jpg" + assetVersion} alt="" />
                    </div>
                    <div className="t-cleansing__color__txt">
                      <div className="t-cleansing__color__txt__inner">
                        <p className="t-cleansing__color__txt__name">DON'T WORRY, BE HAPPY!</p>
                        <p className="t-cleansing__color__txt__ttl__main">いつも前向きに</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="t-cleansing__color__item">
                  <div className="t-cleansing__color__item__inner">
                    <div className="t-cleansing__color__img">
                      <img src={"/images/about/cleansing/theme_believe_in_yourself.jpg" + assetVersion} alt="" />
                    </div>
                    <div className="t-cleansing__color__txt">
                      <div className="t-cleansing__color__txt__inner">
                        <p className="t-cleansing__color__txt__name">BELIEVE IN YOURSELF.</p>
                        <p className="t-cleansing__color__txt__ttl__main">自分を信じて</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="t-cleansing__color__item">
                  <div className="t-cleansing__color__item__inner">
                    <div className="t-cleansing__color__img">
                      <img src={"/images/about/cleansing/theme_let_s_have_a_break.jpg" + assetVersion} alt="" />
                    </div>
                    <div className="t-cleansing__color__txt">
                      <div className="t-cleansing__color__txt__inner">
                        <p className="t-cleansing__color__txt__name">LET'S HAVE A BREAK.</p>
                        <p className="t-cleansing__color__txt__ttl__main">ゆっくり深呼吸</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="t-cleansing__color__item">
                  <div className="t-cleansing__color__item__inner">
                    <div className="t-cleansing__color__img">
                      <img src={"/images/about/cleansing/theme_keep_smiling.jpg" + assetVersion} alt="" />
                    </div>
                    <div className="t-cleansing__color__txt">
                      <div className="t-cleansing__color__txt__inner">
                        <p className="t-cleansing__color__txt__name">KEEP SMILING.</p>
                        <p className="t-cleansing__color__txt__ttl__main">いつも笑顔で</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </section>

          <section className="t-cleansing__offer">
            <div className="t-cleansing__offer__img">
              <img src={"/images/about/cleansing/offer.jpg" + assetVersion} alt="" />
            </div>
            <div className="t-cleansing__offer__txt">
              <div className="t-cleansing__offer__ttl">
                <p className="t-cleansing__offer__ttl__en">HOTARU PERSONALIZED CLEANSING</p>
                <p className="t-cleansing__offer__ttl__ja">ホタルパーソナライズドクレンジング</p>
              </div>
              <div className="t-cleansing__offer__inline-box">
                <div className="t-cleansing__offer__desc">
                  <div className="t-cleansing__offer__desc__cycle">８週間に<br />１回お届け(定期)</div>
                  <div className="t-cleansing__offer__desc__price">初回<br /><span>2,395円<small>(税込)</small></span></div>
                </div>
                <div className="t-cleansing__offer__desc__notice"><small>※2回目のみ4週間後</small></div>
              </div>
              <ul className="t-cleansing__offer__notice">
                <li>通常価格:4,791円(税込)</li>
                <li>2回目以降:2本で8,624円(税込)</li>
                <li>内容量:90ml(約1ヶ月分)</li>
                <li>送料:650 円（沖縄・北海道・離島の場合 800 円）</li>
                <li>マイページに記載の次回出荷予定日の11日前（変更締切日）までに解約のお電話がない限り契約が続き、２ヶ月毎に自動で商品をお届けいたします。※土日祝その他休業日は前営業日。不良品や品違いの場合、交換・キャンセル可。 解約の電話番号：0570-666-886（月〜金　9:00〜18:00  ※ 祝日除く）</li>
                <li><span>ご使用方法は<a href="https://sparty.my.site.com/spartyshopfaq/s/article/HOTARU-product-3">こちら</a></span></li>
              </ul>
              <div className="t-cleansing__offer__cta">
                <a href={quizUrl}>購入する</a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
)
export default AboutCleansingPage
